.empty_list_container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  .message {
    //color: #1c1b20;
    font-size: 22px;
  }
  .illustration {
    max-width: 300px;
  }
}
