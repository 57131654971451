@import url('https://fonts.googleapis.com/css2?family=Clash+Display:wght@400;500;600;700&display=swap');

.auth-layout {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    background: linear-gradient(160deg, rgba(30,61,69,1) 0%, rgba(26,46,70,1) 35%, rgba(8,9,26,1) 100%);
}

/* Top-right small gradient */
.auth-layout::before {
    content: "";
    position: absolute;
    top: -150px;
    right: -200px;
    width: 500px;
    height: 455px;
    background: radial-gradient(circle, rgba(88, 240, 129, 1) 10%, rgba(88, 240, 129, 0.5) 40%, rgba(88, 240, 129, 0) 80%);
    filter: blur(120px);
    opacity: 0.8;
}

/* Middle gradient */
.auth-layout::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 55%;
    width: 413px;
    height: 383px;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle, rgba(55, 55, 215, 1) 20%, rgba(55, 55, 215, 0.5) 50%, rgba(55, 55, 215, 0) 80%);
    filter: blur(120px);
    opacity: 0.4;
    z-index: 1;
}

/* Auth Content  */
.auth-layout-content {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
}

/* players effect */
.auth-layout-players {
    position: absolute;
    bottom: 10px;
    right: 40%;
    width: 560px;
    height: 630px;
    background-image: url('../assets/players.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}

/* Bottom visual grid effect */
.auth-layout-grid {
    width: 100%;
    height: 90px;
    position: absolute;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../assets/grid.png');
}