html {
  box-sizing: border-box;
}

html, body {
  padding: 0 !important;
  margin: 0 !important;
}

*, *:before, *:after {
  box-sizing: inherit;
}

iframe {
  display: none;
  pointer-events: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #4155ec #f6f6f8;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 14px;
  }

  *::-webkit-scrollbar-track {
    background: #f6f6f8;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #7d7d7d;
    border-radius: 20px;
    border: 2px solid #f6f6f8;
  }
